import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import InputMask from "react-input-mask";
import moment from 'moment';
import { encode } from 'js-base64';
import { cnpj } from 'cpf-cnpj-validator';
import { buscaEndereco } from '../../../services/Localidades'
import CurrencyInput from 'react-currency-input';
import { login, postFrete } from '../../../services/Brudam'
import { criaEmpresa, geraCotacaoNegocio, geraTask, getEmpresa } from '../../../services/Agendor'

export default function CotacaoAgente(props) {
    // const [dados] = useState()
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('c'))

    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cotacoes: firebase.firestore().collection('cotacao'),
        empresas: firebase.firestore().collection('empresas'),
    })
    const [idCotacao, setIdCotacao] = useState('')
    const [usuario, setUsuario] = useState({})
    const [dadosCotacao, setDadosCotacao] = useState({})
    const [link, setLink] = useState('')

    // Dados pessoais
    const [idEmpresaAgendor, setIdEmpresaAgendor] = useState('')
    const [cnpjEmpresa, setCnpjEmpresa] = useState('')
    const [nomeEmpresa, setNomeEmpresa] = useState('')
    const [nomeRepresentante, setNomeRepresentante] = useState('')
    const [email, setEmail] = useState('')
    const [whatsapp, setWhatsapp] = useState('')
    const [dados, setDados] = useState({})

    // Origem e Destino
    const [cepOrigem, setCepOrigem] = useState('')
    const [cepDestino, setCepDestino] = useState('')
    const [dadosCepOrigem, setDadosCepOrigem] = useState({})
    const [dadosCepDestino, setDadosCepDestino] = useState({})
    const [pagamentoFrete, setPagamentoFrete] = useState('')
    const [entrega, setEntrega] = useState('')

    // Volumes
    const [produtos, setProdutos] = useState([])
    const [index, setIndex] = useState(null)
    const [nome, setNome] = useState('')
    const [comprimento, setComprimento] = useState(0)
    const [largura, setLargura] = useState(0)
    const [altura, setAltura] = useState(0)
    const [qtd, setQtd] = useState(0)
    const [peso, setPeso] = useState(0)
    const [valor, setValor] = useState(0)
    const [valorDesconto, setValorDesconto] = useState(0)

    // Serviço
    const [servico, setServico] = useState('')
    const [tabela, setTabela] = useState('')

    // Cotação
    const [dadosEnvioCotacao, setDadosEnvioCotacao] = useState({})
    const [cotacao, setCotacao] = useState({})
    const [valorComissao, setValorComissao] = useState(0)

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
                if (id) {
                    servicos.cotacoes.doc(id).get().then((res) => {
                        setIdCotacao(res.data().cotacao)
                        // Dados da Empresa
                        setNomeEmpresa(res.data().empresa.nome)
                        setCnpjEmpresa(res.data().empresa.cnpj)
                        setNomeRepresentante(res.data().empresa.representante)
                        setWhatsapp(res.data().empresa.whatsapp)
                        setEmail(res.data().empresa.email)

                        // Dados Origem e Destino
                        setCepOrigem(res.data().origem.cep)
                        setDadosCepOrigem(res.data().origem)
                        setCepDestino(res.data().destino.cep)
                        setDadosCepDestino(res.data().destino)
                        setPagamentoFrete(res.data().pagamentoFrete)
                        setEntrega(res.data().entrega)

                        // Produtos
                        servicos.cotacoes.doc(id).collection('produtos').get().then((resProd) => {
                            const data = resProd.docs.map((d) => d.data())
                            setProdutos(data)
                        })
                        setValor(res.data().valorNota)
                        setValorDesconto(res.data().valorDesconto)

                        // prazo: cotacao.dados[0].nDias,
                        // servico: servico,
                        // tabela: tabela,
                        // advalore: parseFloat(cotacao.dados[0].vAdv),
                        // icms: parseFloat(cotacao.dados[0].vICMS),
                        // gris: parseFloat(cotacao.dados[0].vGris),
                        // pedagio: parseFloat(cotacao.dados[0].vPedagio),
                        // valorTotal: parseFloat(cotacao.dados[0].vTotal),
                        // valorNota: parseFloat(valor),
                        // valorFrete: (parseFloat(cotacao.dados[0].vTotal) - (parseFloat(cotacao.dados[0].vAdv) + parseFloat(cotacao.dados[0].vGris) + parseFloat(cotacao.dados[0].vICMS) + parseFloat(cotacao.dados[0].vPedagio))),
                        // comissao: parseInt(usuario.comissao),
                        // valorComissao: (parseFloat(cotacao.dados[0].vTotal) * parseInt(usuario.comissao) / 100),

                        // Serviços
                        setServico(res.data().servico)
                        setTabela(res.data().tabela)
                        setCotacao({
                            dados: [{
                                nDias: res.data().prazo,
                                vAdv: res.data().advalore,
                                vICMS: res.data().icms,
                                vGris: res.data().gris,
                                vPedagio: res.data().pedagio,
                                vTotal: res.data().valorTotal
                            }]
                        })
                        setValorComissao(res.data().valorComissao)
                        setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                    })
                }

            }
        })

    }, [])


    function enviaCotacao() {
        if (!cnpj.isValid(cnpjEmpresa.replace(/[^a-z0-9]/gi, ''))) {
            mensagemAlerta('CNPJ inválido')
            return
        }
        if (!nomeEmpresa) {
            mensagemAlerta('Digite o nome da Empresa')
            return
        }
        if (!nomeRepresentante) {
            mensagemAlerta('Digite o nome do Representante da empresa')
            return
        }
        if (!whatsapp || whatsapp.replace(/[^a-z0-9]/gi, '').length < 11) {
            mensagemAlerta('Whatsapp incompleto! Digite o DDD + 9 + Número')
            return
        }
        if (!email || !email.includes('@')) {
            mensagemAlerta('Email inválido. Exemplo: email@email.com')
            return
        }
        if (!cepOrigem || cepOrigem.replace(/[^a-z0-9]/gi, '').length < 8) {
            mensagemAlerta('Digite o Cep de origem')
            return
        }
        if (!cepDestino || cepDestino.replace(/[^a-z0-9]/gi, '').length < 8) {
            mensagemAlerta('Digite o Cep de Destino')
            return
        }
        if (produtos.length == 0) {
            mensagemAlerta('Adicione ao menos um produto')
            return
        }
        if (!valor || valor == 0) {
            mensagemAlerta('Digite o valor da nota')
            return
        }
        if (!servico) {
            mensagemAlerta('Por favor escolha o serviço acima')
            return
        }
        setCotacao({})
        let pesoBruto = 0
        let qtdVol = 0
        let pesoCubado = 0

        produtos.forEach((d) => {
            console.log(d.dAlt / 100)
            pesoBruto = parseFloat(pesoBruto) + parseFloat(d.pBru)
            qtdVol = parseInt(qtdVol) + parseInt(d.qVol)
            pesoCubado = parseFloat(pesoCubado) + ((parseFloat(d.dAlt / 100) * parseFloat(d.dLar / 100) * parseFloat(d.dCom / 100)) * 300) * parseInt(d.qVol)
        })
        const data = {
            nDocEmit: "27069620000133",
            nDocCli: "27069620000133",
            cOrigCalc: dadosCepOrigem.ibge,
            cDestCalc: dadosCepDestino.ibge,
            cServ: servico,
            pBru: parseFloat(pesoBruto),
            cTab: tabela,
            qVol: parseInt(qtdVol),
            vNF: parseFloat(valor),
            volumes: produtos
        }

        const dadosEnvio = {
            agente: usuario,
            cnpjEmpresa: cnpjEmpresa,
            nomeEmpresa: nomeEmpresa,
            nomeRepresentante: nomeRepresentante,
            email: email,
            whatsapp: whatsapp,
            origem: {
                cep: cepOrigem,
                logradouro: dadosCepOrigem.logradouro,
                bairro: dadosCepOrigem.bairro,
                localidade: dadosCepOrigem.localidade,
                uf: dadosCepOrigem.uf,
                ibge: dadosCepOrigem.ibge
            },
            destino: {
                cep: cepDestino,
                logradouro: dadosCepDestino.logradouro,
                bairro: dadosCepDestino.bairro,
                localidade: dadosCepDestino.localidade,
                uf: dadosCepDestino.uf,
                ibge: dadosCepDestino.ibge
            },
            servico: servico,
            tabela: tabela,
            valorNota: parseFloat(valor),
            status: 'CADASTRADO'
        }
        mensagemLoading('Gerando Cotação..')
        login().then((res) => {
            postFrete(res.data.access_key, data).then((doc) => {

                if (doc.message === 'OK') {
                    setDadosEnvioCotacao(data)
                    let val = 0
                    doc.data.forEach((d) => {
                        val = parseFloat(val) + parseFloat(d.vTotal)
                    })
                    if (val > 0) {
                        Swal.close();
                        const data = {
                            dados: doc.data.filter((d) => d.vPeso > 0),
                            frete: {
                                produtos: dados.volumes,
                                cepOrigem: dados.origem,
                                cepDestino: dados.destino,
                                servico: dados.servico
                            }
                        }
                        dadosEnvio.entrega = data.dados.filter((d) => d.vPeso > 0)[0].nDias
                        dadosEnvio.advalore = parseFloat(data.dados[0].vAdv)
                        dadosEnvio.icms = parseFloat(data.dados[0].vICMS)
                        dadosEnvio.gris = parseFloat(data.dados[0].vGris)
                        dadosEnvio.pedagio = parseFloat(data.dados[0].vPedagio)
                        dadosEnvio.valorTotal = parseFloat(data.dados[0].vTotal)
                        dadosEnvio.valorFrete = (parseFloat(data.dados[0].vTotal) - parseFloat(data.dados[0].vAdv) - parseFloat(data.dados[0].vGris) + parseFloat(data.dados[0].vICMS) - parseFloat(data.dados[0].vPedagio))
                        setDadosCotacao(dadosEnvio)
                        setCotacao(data)
                        if (id) {
                            atualizaCotacao(data)
                        } else {
                            salvaCotacao(data)
                        }

                    } else {
                        mensagemAlerta('Trecho da cotação ainda não disponível')
                    }
                } else {
                    mensagemAlerta(doc.message)
                }
            })
        })
    }

    function adicionaProduto() {
        if (!nome) {
            mensagemAlerta('Informe o nome do produto')
            return
        }
        if (!comprimento) {
            mensagemAlerta('Informe o comprimento do volume')
            return
        }
        if (!largura) {
            mensagemAlerta('Informe a largura do volume')
            return
        }
        if (!altura) {
            mensagemAlerta('Informe a altura do volume')
            return
        }
        if (!qtd) {
            mensagemAlerta('Informe a quantidade de volumes')
            return
        }
        if (!peso) {
            mensagemAlerta('Informe o peso total do volume')
            return
        }
        const list = []
        const dados = {
            nome: nome,
            dAlt: parseFloat(altura),
            dLar: parseFloat(largura),
            dCom: parseFloat(comprimento),
            pBru: parseFloat(peso),
            qVol: parseInt(qtd),
        }
        if (index !== null) {
            produtos[index].nome = nome
            produtos[index].dAlt = parseFloat(altura)
            produtos[index].dLar = parseFloat(largura)
            produtos[index].dCom = parseFloat(comprimento)
            produtos[index].pBru = parseFloat(peso)
            produtos[index].qVol = parseFloat(qtd)

            cancelaAlteracao()
        } else {
            list.push(
                ...produtos,
                dados
            )
            setNome('')
            setComprimento(0)
            setLargura(0)
            setAltura(0)
            setQtd(0)
            setPeso(0)
            setProdutos(list)
        }

    }

    function removeProduto(p, index) {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja remover o produto ' + p.nome + ' ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                const list = []
                for (let i = 0; i < produtos.length; i++) {
                    const element = produtos[i];
                    if (i !== index) {
                        list.push(element)
                    }
                }
                cancelaAlteracao()
                setProdutos(list)
            }
        });

    }

    function editaProduto(item, ind) {
        console.log(item.dCom)
        setIndex(ind)
        setComprimento(item.dCom)
        setLargura(item.dLar)
        setAltura(item.dAlt)
        setPeso(item.pBru)
        setQtd(item.qVol)
        setNome(item.nome)
    }

    function cancelaAlteracao() {
        setIndex(null)
        setNome('')
        setComprimento(0)
        setLargura(0)
        setAltura(0)
        setQtd(0)
        setPeso(0)
    }

    function setaServico(serv, tab) {
        setServico(serv)
        setTabela(tab)
    }

    // Dados Pessoais
    const handleCnpjEmpresa = (event) => {
        event.persist();
        setCnpjEmpresa(event.target.value);
        if (cnpj.isValid(event.target.value.replace(/[^a-z0-9]/gi, ''))) {
            servicos.empresas.where('cnpjEmpresa', '==', event.target.value).where('agente.id', '==', usuario.id).get().then((res) => {
                mensagemLoading('Buscando Cliente..')
                if (res.size > 0) {
                    setNomeEmpresa(res.docs.map((d) => d.data())[0].nome)
                    setNomeRepresentante(res.docs.map((d) => d.data())[0].representante)
                    setWhatsapp(res.docs.map((d) => d.data())[0].whatsapp)
                    setEmail(res.docs.map((d) => d.data())[0].email)
                    Swal.close()
                } else {
                    Swal.close()
                }
            })
            getEmpresa(event.target.value.replace(/[^a-z0-9]/gi, '')).then((res) => {
                if (res.data.length > 0) {
                    setIdEmpresaAgendor(res.data[0].id)
                }
            })
        }
    }
    const handleNomeEmpresa = (event) => {
        event.persist();
        setNomeEmpresa(event.target.value);
    }
    const handleNomeRepresentante = (event) => {
        event.persist();
        setNomeRepresentante(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleWhatsapp = (event) => {
        event.persist();
        setWhatsapp(event.target.value);
    }

    // Endereço
    const handleCepOrigem = (event) => {
        event.persist();
        setDadosCepOrigem({})
        setCepOrigem(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Carregando dados..')
            setDadosCepOrigem({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    Swal.close()
                    mensagemAlerta('Endereço não localizado')
                } else {
                    Swal.close()
                    setDadosCepOrigem(dados)
                }
            })
        }
    }
    const handleCepDestino = (event) => {
        event.persist();
        setDadosCepDestino({})
        setCepDestino(event.target.value);
        if (event.target.value.length === 8) {
            mensagemLoading('Carregando dados..')
            setDadosCepDestino({})
            buscaEndereco(event.target.value).then((dados) => {
                if (dados.erro) {
                    Swal.close()
                    mensagemAlerta('Endereço não localizado')
                } else {
                    Swal.close()
                    setDadosCepDestino(dados)
                }
            })
        }
    }
    const handlePagamentoFrete = (event) => {
        event.persist();
        setPagamentoFrete(event.target.value)
    }
    const handleEntrega = (event) => {
        event.persist();
        setEntrega(event.target.value)
    }

    function enviarCotacao() {
        Swal.fire({
            title: 'Aviso',
            text: 'Deseja enviar a cotação para Entrega Já? Após confirmar, não será mais possível editar',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.value) {
                mensagemLoading('Enviando Cotação...')
                servicos.empresas.where('cnpjEmpresa', '==', cnpjEmpresa).get().then((res) => {
                    if (res.size > 0) {
                        servicos.cotacoes.doc(id).update({
                            status: 'ANALISE',
                            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                            valorDesconto: parseFloat(valorDesconto)
                        }).then(() => {
                            if (idEmpresaAgendor) {
                                const dadosNegocio = {
                                    title: 'Cotação ' + nomeEmpresa + ' - ' + 'COT' + (res.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                    dealStatusText: "ongoing",
                                    description: `Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=` + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                    dealStage: 2918853,
                                    value: parseFloat(valor),
                                    funnel: 714912,
                                    ranking: 5,
                                    ownerUser: 'renata.soares@entregaja.com.br',
                                    customFields: {
                                        link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id
                                    }

                                }
                                geraCotacaoNegocio(idEmpresaAgendor, dadosNegocio).then((resNegocio) => {
                                    servicos.cotacao.doc(id).update({
                                        idCotacaoAgendor: resNegocio.data.id,
                                        idEmpresaAgendor: idEmpresaAgendor
                                    })
                                    const dadosTask = {
                                        text: 'Cotação ' + nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                        type: "PROPOSTA",
                                    }
                                    geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                        servicos.cotacoes.doc(id).update({
                                            idNegocioAgendor: resNegocio.data.id
                                        }).then(() => {
                                            servicos.cotacoes.doc(id).collection('historicos').add({
                                                acao: 'COTAÇÃO EM ANÁLISE',
                                                descricao: 'Cotação foi enviada para análise do Analista',
                                                observacao: 'Cotação COT' + idCotacao + ' foi atualizada',
                                                status: 'ANALISE',
                                                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                                usuario: {
                                                    id: usuario.id,
                                                    nome: usuario.agente,
                                                    email: usuario.email
                                                },
                                            }).then(() => {
                                                setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                                                mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')
                                            })
                                        })
                                    })
                                })
                            } else {
                                const dadosEmpresa = {
                                    name: nomeEmpresa,
                                    cnpj: cnpjEmpresa,
                                    ranking: "3",
                                    contact: {
                                        email: email,
                                        whatsapp: whatsapp
                                    }
                                }
                                criaEmpresa(dadosEmpresa).then((resEmpresa) => {
                                    const dadosNegocio = {
                                        title: 'Cotação ' + nomeEmpresa + ' - ' + 'COT' + (res.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                        dealStatusText: "ongoing",
                                        description: "Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                        dealStage: 2918853,
                                        value: parseFloat(valor),
                                        funnel: 714912,
                                        ranking: 5,
                                        ownerUser: 'renata.soares@entregaja.com.br',
                                        customFields: {
                                            link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id
                                        }

                                    }
                                    geraCotacaoNegocio(resEmpresa.data.id, dadosNegocio).then((resNegocio) => {
                                        servicos.cotacao.doc(id).update({
                                            idCotacaoAgendor: resNegocio.data.id,
                                            idEmpresaAgendor: resEmpresa.data.id
                                        })
                                        const dadosTask = {
                                            text: 'Cotação ' + nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                            type: "PROPOSTA",
                                        }
                                        geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                            servicos.cotacoes.doc(id).update({
                                                empresa: {
                                                    cnpj: cnpjEmpresa,
                                                    email: email,
                                                    whatsapp: whatsapp,
                                                    representante: nomeRepresentante,
                                                    idEmpresaAgendor: resEmpresa.data.id
                                                },
                                                idNegocioAgendor: resNegocio.data.id
                                            }).then(() => {
                                                servicos.cotacoes.doc(id).collection('historicos').add({
                                                    acao: 'COTAÇÃO EM ANÁLISE',
                                                    descricao: 'Cotação foi enviada para análise do Analista',
                                                    observacao: 'Cotação COT' + idCotacao + ' foi atualizada',
                                                    status: 'ANALISE',
                                                    dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                                    usuario: {
                                                        id: usuario.id,
                                                        nome: usuario.agente,
                                                        email: usuario.email
                                                    },
                                                }).then(() => {
                                                    setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                                                    mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')
                                                })
                                            })
                                        })
                                    })
                                })
                            }
                        })
                    } else {
                        Swal.fire({
                            title: 'Aviso',
                            text: 'Deseja salvar a empresa para futuras Cotações?',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não'
                        }).then((result) => {
                            if (result.value) {
                                mensagemLoading('Cadastrando Empresa...')
                                servicos.empresas.add({
                                    agente: {
                                        id: usuario.id,
                                        email: usuario.email,
                                        agente: usuario.agente
                                    },
                                    nome: nomeEmpresa,
                                    cnpjEmpresa: cnpjEmpresa,
                                    representante: nomeRepresentante,
                                    whatsapp: whatsapp,
                                    email: email,
                                }).then((resId) => {
                                    servicos.cotacoes.doc(id).update({
                                        status: 'ANALISE',
                                        dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                                        valorDesconto: parseFloat(valorDesconto),
                                        empresa: {
                                            id: resId.id,
                                            cnpj: cnpjEmpresa,
                                            email: email,
                                            whatsapp: whatsapp,
                                            representante: nomeRepresentante,
                                            idEmpresaAgendor: ''
                                        },
                                    }).then(() => {
                                        mensagemLoading('Enviando Cotação...')
                                        if (idEmpresaAgendor) {
                                            const dadosNegocio = {
                                                title: 'Cotação ' + nomeEmpresa + ' - ' + 'COT' + (res.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                                dealStatusText: "ongoing",
                                                description: `Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=` + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                                dealStage: 2918853,
                                                value: parseFloat(valor),
                                                funnel: 714912,
                                                ranking: 5,
                                                ownerUser: 'renata.soares@entregaja.com.br',
                                                customFields: {
                                                    link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id
                                                }

                                            }
                                            geraCotacaoNegocio(idEmpresaAgendor, dadosNegocio).then((resNegocio) => {
                                                servicos.cotacao.doc(id).update({
                                                    idCotacaoAgendor: resNegocio.data.id,
                                                    idEmpresaAgendor: idEmpresaAgendor
                                                })
                                                const dadosTask = {
                                                    text: 'Cotação ' + nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                                    type: "PROPOSTA",
                                                }
                                                geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                                    servicos.cotacoes.doc(id).update({
                                                        idNegocioAgendor: resNegocio.data.id
                                                    }).then(() => {
                                                        servicos.cotacoes.doc(id).collection('historicos').add({
                                                            acao: 'COTAÇÃO EM ANÁLISE',
                                                            descricao: 'Cotação foi enviada para análise do Analista',
                                                            observacao: 'Cotação COT' + idCotacao + ' foi atualizada',
                                                            status: 'ANALISE',
                                                            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                                            usuario: {
                                                                id: usuario.id,
                                                                nome: usuario.agente,
                                                                email: usuario.email
                                                            },
                                                        }).then(() => {
                                                            setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                                                            mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')
                                                        })
                                                    })
                                                })
                                            })
                                        } else {
                                            const dadosEmpresa = {
                                                name: nomeEmpresa,
                                                cnpj: cnpjEmpresa,
                                                ranking: "3",
                                                contact: {
                                                    email: email,
                                                    whatsapp: whatsapp
                                                }
                                            }
                                            criaEmpresa(dadosEmpresa).then((resEmpresa) => {
                                                const dadosNegocio = {
                                                    title: 'Cotação ' + nomeEmpresa + ' - ' + 'COT' + (res.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                                    dealStatusText: "ongoing",
                                                    description: "Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                                    dealStage: 2918853,
                                                    value: parseFloat(valor),
                                                    funnel: 714912,
                                                    ranking: 5,
                                                    ownerUser: 'renata.soares@entregaja.com.br',
                                                    customFields: {
                                                        link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id
                                                    }

                                                }
                                                geraCotacaoNegocio(resEmpresa.data.id, dadosNegocio).then((resNegocio) => {
                                                    servicos.cotacao.doc(id).update({
                                                        idCotacaoAgendor: resNegocio.data.id,
                                                        idEmpresaAgendor: resEmpresa.data.id
                                                    })
                                                    const dadosTask = {
                                                        text: 'Cotação ' + nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                                        type: "PROPOSTA",
                                                    }
                                                    geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                                        servicos.cotacoes.doc(id).update({
                                                            empresa: {
                                                                id: resId.id,
                                                                cnpj: cnpjEmpresa,
                                                                email: email,
                                                                whatsapp: whatsapp,
                                                                representante: nomeRepresentante,
                                                                idEmpresaAgendor: resEmpresa.data.id
                                                            },
                                                            idNegocioAgendor: resNegocio.data.id
                                                        }).then(() => {
                                                            servicos.cotacoes.doc(id).collection('historicos').add({
                                                                acao: 'COTAÇÃO EM ANÁLISE',
                                                                descricao: 'Cotação foi enviada para análise do Analista',
                                                                observacao: 'Cotação COT' + idCotacao + ' foi atualizada',
                                                                status: 'ANALISE',
                                                                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                                                usuario: {
                                                                    id: usuario.id,
                                                                    nome: usuario.agente,
                                                                    email: usuario.email
                                                                },
                                                            }).then(() => {
                                                                setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                                                                mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')
                                                            })
                                                        })
                                                    })
                                                })
                                            })
                                        }
                                    })
                                })
                            } else {
                                servicos.cotacoes.doc(id).update({
                                    status: 'ANALISE',
                                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                                    valorDesconto: parseFloat(valorDesconto),
                                }).then(() => {
                                    mensagemLoading('Enviando Cotação...')
                                    if (idEmpresaAgendor) {
                                        const dadosNegocio = {
                                            title: 'Cotação ' + nomeEmpresa + ' - ' + 'COT' + (res.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                            dealStatusText: "ongoing",
                                            description: `Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=` + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                            dealStage: 2918853,
                                            value: parseFloat(valor),
                                            funnel: 714912,
                                            ranking: 5,
                                            ownerUser: 'renata.soares@entregaja.com.br',
                                            customFields: {
                                                link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id
                                            }

                                        }
                                        geraCotacaoNegocio(idEmpresaAgendor, dadosNegocio).then((resNegocio) => {
                                            servicos.cotacao.doc(id).update({
                                                idCotacaoAgendor: resNegocio.data.id,
                                                idEmpresaAgendor: idEmpresaAgendor
                                            })
                                            const dadosTask = {
                                                text: 'Cotação ' + nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                                type: "PROPOSTA",
                                            }
                                            geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                                servicos.cotacoes.doc(id).update({
                                                    idNegocioAgendor: resNegocio.data.id
                                                }).then(() => {
                                                    servicos.cotacoes.doc(id).collection('historicos').add({
                                                        acao: 'COTAÇÃO EM ANÁLISE',
                                                        descricao: 'Cotação foi enviada para análise do Analista',
                                                        observacao: 'Cotação COT' + idCotacao + ' foi atualizada',
                                                        status: 'ANALISE',
                                                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                                        usuario: {
                                                            id: usuario.id,
                                                            nome: usuario.agente,
                                                            email: usuario.email
                                                        },
                                                    }).then(() => {
                                                        setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                                                        mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')
                                                    })
                                                })
                                            })
                                        })
                                    } else {
                                        const dadosEmpresa = {
                                            name: nomeEmpresa,
                                            cnpj: cnpjEmpresa,
                                            ranking: "3",
                                            contact: {
                                                email: email,
                                                whatsapp: whatsapp
                                            }
                                        }
                                        criaEmpresa(dadosEmpresa).then((resEmpresa) => {
                                            const dadosNegocio = {
                                                title: 'Cotação ' + nomeEmpresa + ' - ' + 'COT' + (res.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY'),
                                                dealStatusText: "ongoing",
                                                description: "Cotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                                dealStage: 2918853,
                                                value: parseFloat(valor),
                                                funnel: 714912,
                                                ranking: 5,
                                                ownerUser: 'renata.soares@entregaja.com.br',
                                                customFields: {
                                                    link: "https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id
                                                }

                                            }
                                            geraCotacaoNegocio(resEmpresa.data.id, dadosNegocio).then((resNegocio) => {
                                                servicos.cotacao.doc(id).update({
                                                    idCotacaoAgendor: resNegocio.data.id,
                                                    idEmpresaAgendor: resEmpresa.data.id
                                                })
                                                const dadosTask = {
                                                    text: 'Cotação ' + nomeEmpresa + "\n\nCotação gerada e pode acompanhar em : \n\n https://cotacao.entregaja.com.br/resultadoCotacao?c=" + id + '\n\n Cotação gerada pelo o Agente: ' + usuario.nome,
                                                    type: "PROPOSTA",
                                                }
                                                geraTask(resNegocio.data.id, dadosTask).then((resTask) => {
                                                    servicos.cotacoes.doc(id).update({
                                                        empresa: {
                                                            cnpj: cnpjEmpresa,
                                                            email: email,
                                                            whatsapp: whatsapp,
                                                            representante: nomeRepresentante,
                                                            idEmpresaAgendor: resEmpresa.data.id
                                                        },
                                                        idNegocioAgendor: resNegocio.data.id
                                                    }).then(() => {
                                                        servicos.cotacoes.doc(id).collection('historicos').add({
                                                            acao: 'COTAÇÃO EM ANÁLISE',
                                                            descricao: 'Cotação foi enviada para análise do Analista',
                                                            observacao: 'Cotação COT' + idCotacao + ' foi atualizada',
                                                            status: 'ANALISE',
                                                            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                                                            usuario: {
                                                                id: usuario.id,
                                                                nome: usuario.agente,
                                                                email: usuario.email
                                                            },
                                                        }).then(() => {
                                                            setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                                                            mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    }
                                })
                            }
                        });
                    }
                })

            }
        });
    }

    function atualizaCotacao(novaCotacao) {
        mensagemLoading('Atualizando cotação..')
        servicos.cotacoes.doc(id).update({
            agente: {
                id: usuario.id,
                email: usuario.email,
                agente: usuario.agente
            },
            empresa: {
                id: '',
                nome: nomeEmpresa,
                whatsapp: whatsapp,
                email: email,
                cnpj: cnpjEmpresa,
                representante: nomeRepresentante,
            },
            origem: dadosCepOrigem,
            destino: dadosCepDestino,
            dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
            dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
            mes: moment().format('MM'),
            ano: moment().format('YYYY'),
            entrega: entrega,
            pagamentoFrete: pagamentoFrete,
            prazo: novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].nDias : cotacao.dados[0].nDias,
            servico: servico,
            tabela: tabela,
            advalore: parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vAdv : cotacao.dados[0].vAdv),
            icms: parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vICMS : cotacao.dados[0].vICMS),
            gris: parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vGris : cotacao.dados[0].vGris),
            pedagio: parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vPedagio : cotacao.dados[0].vPedagio),
            valorTotal: parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vTotal : cotacao.dados[0].vTotal),
            valorNota: parseFloat(valor),
            valorFrete: (parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vTotal : cotacao.dados[0].vTotal) - (parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vAdv : cotacao.dados[0].vAdv) + parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vGris : cotacao.dados[0].vGris) + parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vICMS : cotacao.dados[0].vICMS) + parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vPedagio : cotacao.dados[0].vPedagio))),
            comissao: parseInt(usuario.comissao),
            valorComissao: (parseFloat(novaCotacao && novaCotacao.dados ? novaCotacao.dados[0].vTotal : cotacao.dados[0].vTotal) * parseInt(usuario.comissao) / 100),
            trecho: true,
            valorDesconto: parseFloat(valorDesconto),
            status: 'COTACAO',
        }).then((snap) => {
            produtos.forEach((d) => {
                servicos.cotacoes.doc(id).collection('produtos').doc(d.id).delete();
            })
            produtos.forEach((d) => {
                servicos.cotacoes.doc(id).collection('produtos').add(d).then((pr) => {
                    servicos.cotacoes.doc(id).collection('produtos').doc(pr.id).update({
                        id: pr.id
                    })
                })
            })
            servicos.cotacoes.doc(id).collection('historicos').add({
                acao: 'Atualização de Cotação',
                descricao: 'Cotação atualizada',
                observacao: 'Cotação COT' + idCotacao + ' foi atualizada',
                status: 'COTACAO',
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                usuario: {
                    id: usuario.id,
                    nome: usuario.agente,
                    email: usuario.email
                },
            }).then(() => {
                setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + id)
                mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')
            })
        }).catch((er) => {
            mensagemErro('Erro ao cadastrar cotação ' + er)
        })
    }

    function salvaCotacao(cotacao) {
        mensagemLoading('Cadastrando sua cotação..')
        servicos.cotacoes.get().then((q) => {
            servicos.cotacoes.add({
                agente: {
                    id: usuario.id,
                    email: usuario.email,
                    agente: usuario.agente
                },
                empresa: {
                    id: '',
                    nome: nomeEmpresa,
                    whatsapp: whatsapp,
                    email: email,
                    cnpj: cnpjEmpresa,
                    representante: nomeRepresentante,
                },
                origem: dadosCepOrigem,
                destino: dadosCepDestino,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                dataAtualizacao: moment().format('YYYY-MM-DD HH:mm'),
                mes: moment().format('MM'),
                ano: moment().format('YYYY'),
                entrega: entrega,
                pagamentoFrete: pagamentoFrete,
                prazo: cotacao.dados[0].nDias,
                servico: servico,
                tabela: tabela,
                advalore: parseFloat(cotacao.dados[0].vAdv),
                icms: parseFloat(cotacao.dados[0].vICMS),
                gris: parseFloat(cotacao.dados[0].vGris),
                pedagio: parseFloat(cotacao.dados[0].vPedagio),
                valorTotal: parseFloat(cotacao.dados[0].vTotal),
                valorNota: parseFloat(valor),
                valorFrete: (parseFloat(cotacao.dados[0].vTotal) - (parseFloat(cotacao.dados[0].vAdv) + parseFloat(cotacao.dados[0].vGris) + parseFloat(cotacao.dados[0].vICMS) + parseFloat(cotacao.dados[0].vPedagio))),
                comissao: parseInt(usuario.comissao),
                valorComissao: (parseFloat(cotacao.dados[0].vTotal) * parseInt(usuario.comissao) / 100),
                trecho: true,
                valorDesconto: 0,
                status: 'COTACAO',
                tipo: 'AGENTE',
                cotacao: 'COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY')
            }).then((snap) => {
                servicos.cotacoes.doc(snap.id).update({
                    id: snap.id
                }).then(() => {
                    produtos.forEach((d) => {
                        servicos.cotacoes.doc(snap.id).collection('produtos').add(d).then((pr) => {
                            servicos.cotacoes.doc(snap.id).collection('produtos').doc(pr.id).update({
                                id: pr.id
                            })
                        })
                    })
                    servicos.cotacoes.doc(snap.id).collection('historicos').add({
                        acao: 'Cadastro Cotação',
                        descricao: 'Cotação cadastrada',
                        observacao: 'Cotação COT' + (q.size + 1) + '' + moment().format('MM') + '' + moment().format('YYYY') + ' foi cadastrada',
                        status: 'COTACAO',
                        dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                        usuario: {
                            id: usuario.id,
                            nome: usuario.agente,
                            email: usuario.email
                        },
                    }).then(() => {
                        setLink('https://cotacao.entregaja.com.br/resultadoCotacao?c=' + snap.id)
                        mensagemSucesso('Sua cotação foi cadastrada! Envie o link para o cliente realizar a análise')

                    })


                })
            }).catch((er) => {
                mensagemErro('Erro ao cadastrar cotação ' + er)
            })
        }).catch((er) => {
            mensagemErro('Erro ao cadastrar cotação ' + er)
        })
    }

    // Dados do Volume
    const handleProduto = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    function handleComprimento(event, maskedvalue, floatvalue) {
        setComprimento(maskedvalue);

    }
    function handleLargura(event, maskedvalue, floatvalue) {
        setLargura(maskedvalue);
    }
    function handleAltura(event, maskedvalue, floatvalue) {
        setAltura(maskedvalue);
    }
    const handlePeso = (event) => {
        event.persist();
        setPeso(event.target.value);
    }
    const handleQtd = (event) => {
        event.persist();
        setQtd(event.target.value.replace(/[^a-z0-9]/gi, ''));
    }
    function handleValor(event, maskedvalue, floatvalue) {
        setValor(maskedvalue)
    }
    function handleValorDesconto(event, maskedvalue, floatvalue) {
        setValorDesconto(maskedvalue)
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemErro(msg) {
        Swal.fire('Error', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    {usuario.id ?
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                <h5 className="card-header">Nova Cotação</h5>
                                                {/* Account */}
                                                <div className="card-body row">
                                                    <div className="d-flex align-items-start align-items-sm-center gap-4 col-md-2">
                                                        <img src={usuario.id && usuario.avatarEmpresa ? usuario.avatarEmpresa : 'https://png.pngtree.com/png-vector/20190930/ourmid/pngtree-building-icon-isolated-on-abstract-background-png-image_1763153.jpg'} alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />
                                                    </div>
                                                    <div className=" align-items-start align-items-sm-center gap-4 col-md-4">
                                                        <h3>{usuario.agente}</h3>
                                                        <p>{usuario.nomeEmpresa} <br />{usuario.cnpj}</p>
                                                    </div>
                                                </div>
                                                <hr className="my-0" />
                                                <div className="card-body">
                                                    <div onsubmit="return false">
                                                        <div className="row">
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Dados da Empresa</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">CNPJ</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-user"></i></span>
                                                                    <InputMask mask="99.999.999/9999-99" onChange={handleCnpjEmpresa} value={cnpjEmpresa} className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Nome da Empresa</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-buildings"></i></span>
                                                                    <input onChange={handleNomeEmpresa} value={nomeEmpresa} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Nome do Representante</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-user"></i></span>
                                                                    <input onChange={handleNomeRepresentante} value={nomeRepresentante} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Whatsapp</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-phone"></i></span>
                                                                    <InputMask mask="(99) 99999-9999" onChange={handleWhatsapp} value={whatsapp} className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Email</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-envelope"></i></span>
                                                                    <input onChange={handleEmail} value={email} type="text" class="form-control" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <hr className="my-4 " />
                                                        <div className="row" style={{ marginTop: 20 }}>
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Origem e Destino</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <h5 style={{ marginTop: 20 }} class="mb-0">Endereço de Origem</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">digite o cep</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input onChange={handleCepOrigem} value={cepOrigem} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Endereço</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepOrigem.cep ? dadosCepOrigem.logradouro : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Bairro</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepOrigem.cep ? dadosCepOrigem.bairro : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Cidade</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepOrigem.cep ? dadosCepOrigem.localidade : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Estado</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepOrigem.cep ? dadosCepOrigem.uf : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <h5 style={{ marginTop: 20 }} class="mb-0">Endereço de Destino</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">digite o cep</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input onChange={handleCepDestino} value={cepDestino} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Endereço</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepDestino.cep ? dadosCepDestino.logradouro : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Bairro</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepDestino.cep ? dadosCepDestino.bairro : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Cidade</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepDestino.cep ? dadosCepDestino.localidade : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Estado</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={dadosCepDestino.cep ? dadosCepDestino.uf : ''} disabled type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <h5 style={{ marginTop: 20 }} class="mb-0">Pagamento e Entrega</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Quem pagará o Frete?</label>
                                                                <select id="defaultSelect" className="form-select" onChange={handlePagamentoFrete}>
                                                                    <option>Selecione</option>
                                                                    <option value={'ORIGEM'} selected={dados && pagamentoFrete === 'ORIGEM' ? 'selected' : null}>Origem</option>
                                                                    <option value={'DESTINO'} selected={dados && pagamentoFrete === 'DESTINO' ? 'selected' : null}>Destino</option>
                                                                </select>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Dados da Entrega</label>
                                                                <select id="defaultSelect" className="form-select" onChange={handleEntrega}>
                                                                    <option>Selecione</option>
                                                                    <option value={'BASE'} selected={dados && entrega === 'BASE' ? 'selected' : null}>Retira na Base</option>
                                                                    <option value={'DOMICILIO'} selected={dados && entrega === 'DOMICILIO' ? 'selected' : null}>A domicílio</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                        <hr className="my-4 " />
                                                        <div className="row" style={{ marginTop: 20 }}>
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Volumes</h5>
                                                            </div>
                                                            <div class="mb-3 col-md-4">
                                                                <label class="form-label" for="basic-icon-default-fullname">Nome do produto</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={nome} onChange={handleProduto} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Compr</label>
                                                                <div class="input-group input-group-merge">
                                                                    <CurrencyInput className="form-control" id="email2" value={comprimento} onChange={handleComprimento} decimalSeparator="," thousandSeparator="." />
                                                                    {/* <input value={comprimento} onChange={handleComprimento} type="number" class="form-control" /> */}
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">larg</label>
                                                                <div class="input-group input-group-merge">
                                                                    <CurrencyInput className="form-control" id="email2" value={largura} onChange={handleLargura} decimalSeparator="," thousandSeparator="." />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Alt</label>
                                                                <div class="input-group input-group-merge">
                                                                    <CurrencyInput className="form-control" id="email2" value={altura} onChange={handleAltura} decimalSeparator="," thousandSeparator="." />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Peso (kg)</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={peso} onChange={handlePeso} type="number" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-2">
                                                                <label class="form-label" for="basic-icon-default-fullname">Qtd</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input value={qtd} onChange={handleQtd} type="number" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div style={{ marginTop: 30 }} class="mb-3 col-md-3">
                                                                <button onClick={() => adicionaProduto()} type="submit" class="btn btn-info">{index != null ? 'Atualizar' : 'Adicionar'}</button>
                                                            </div>
                                                            {index != null ?
                                                                <div style={{ marginTop: 30 }} class="mb-3 col-md-3">
                                                                    <button onClick={() => cancelaAlteracao()} type="submit" class="btn btn-danger">Cancelar</button>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                            <div className='mb-3 col-md-12'>
                                                                <table class="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Produto</th>
                                                                            <th>medidas(Compr x Larg x Alt)</th>
                                                                            <th>Peso</th>
                                                                            <th>Qtd</th>
                                                                            <th>Ações</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody class="table-border-bottom-0">
                                                                        {produtos.map((p, index) =>
                                                                            <tr>
                                                                                <td>{p.nome}</td>
                                                                                <td>{parseInt(p.dCom * 100)} cm {parseInt(p.dLar * 100)} cm x {parseInt(p.dAlt * 100)} cm </td>
                                                                                <td>{p.pBru} kg</td>
                                                                                <td>{p.qVol}</td>
                                                                                <td>
                                                                                    <div class="dropdown">
                                                                                        <button type="button" class="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                                                            <i class="bx bx-dots-vertical-rounded"></i>
                                                                                        </button>
                                                                                        <div class="dropdown-menu">
                                                                                            <a class="dropdown-item" onClick={() => editaProduto(p, index)} href="javascript:void(0);"
                                                                                            ><i class="bx bx-edit-alt me-1"></i> Editar</a>
                                                                                            <a class="dropdown-item" onClick={() => removeProduto(p, index)} href="javascript:void(0);"
                                                                                            ><i class="bx bx-trash me-1"></i> Excluir</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                                <label class="form-label" for="basic-icon-default-fullname">Valor da Nota</label>
                                                                <div class="input-group input-group-merge">
                                                                    <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                        class="bx bx-money"></i></span>
                                                                    {/* <input onChange={handleValor} value={valor} type="text" class="form-control" /> */}
                                                                    <CurrencyInput className="form-control" id="email2" prefix="R$ " value={valor} onChange={handleValor} decimalSeparator="," thousandSeparator="." />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <hr className="my-4 " />
                                                        <div className="row" style={{ marginTop: 20 }}>
                                                            <div class="mb-3 col-md-12" >
                                                                <h5>Serviços</h5>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="card mb-3">
                                                                    <div class="row g-0">
                                                                        <div class="col-md-12">
                                                                            <div class="card-body">
                                                                                <h5 class="card-title">Entrega Convencional</h5>
                                                                                <p class="card-text">
                                                                                    Serviço de entrega confiável e eficiente para satisfazer suas necessidades de transporte de mercadorias.
                                                                                </p>
                                                                                {servico == '080' && tabela == '132' ?
                                                                                    <button onClick={() => setaServico('080', '132')} type="submit" class="btn btn-success">Selecionado</button>
                                                                                    :
                                                                                    <button onClick={() => setaServico('080', '132')} type="submit" class="btn btn-primary">Selecionar</button>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div class="col-md-6">
                                                                <div class="card mb-3">
                                                                    <div class="row g-0">
                                                                        <div class="col-md-12">
                                                                            <div class="card-body">
                                                                                <h5 class="card-title">Rodo Expresso</h5>
                                                                                <p class="card-text">
                                                                                    Serviço de entrega confiável e eficiente para satisfazer suas necessidades de transporte de mercadorias.
                                                                                </p>
                                                                                {servico == '081' && tabela == '132' ?
                                                                                    <button onClick={() => setaServico('081', '132')} type="submit" class="btn btn-success">Selecionado</button>
                                                                                    :
                                                                                    <button onClick={() => setaServico('081', '132')} type="submit" class="btn btn-primary">Selecionar</button>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                        </div>
                                                        {cotacao.dados ?
                                                            <>
                                                                <hr className="my-4 " />
                                                                <div className="row" style={{ marginTop: 20 }}>
                                                                    <div class="mb-3 col-md-12" >
                                                                        <h5>Resultado</h5>
                                                                    </div>
                                                                    <div class="mb-3 col-md-12">
                                                                        <hr />
                                                                        <h5 style={{ marginTop: 20 }} class="mb-0"><b>Resultado da Cotação</b></h5>
                                                                    </div>
                                                                    <div style={{ marginTop: 20 }} className='col-md-9'>
                                                                        Prazo Previsto de Entrega
                                                                    </div>
                                                                    <div className='col-md-3'>
                                                                        De {cotacao.dados ? cotacao.dados[0].nDias - 2 : 0} á {cotacao.dados ? cotacao.dados[0].nDias : 0} dias úteis
                                                                    </div>
                                                                    <div style={{ marginTop: 30 }} className='col-md-9'>
                                                                        <b style={{ fontSize: 20 }}>Valor Total do Frete</b>
                                                                    </div>
                                                                    <div style={{ marginTop: 20 }} className='col-md-3'>
                                                                        <b style={{ fontSize: 30 }}>{(parseFloat(cotacao.dados[0].vTotal)).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                                                    </div>
                                                                    <div style={{ marginTop: 20 }} className='col-md-9'>
                                                                        <b style={{ fontSize: 20 }}>Valor Comissão</b>
                                                                    </div>
                                                                    <div style={{ marginTop: 10 }} className='col-md-3'>
                                                                        <b style={{ fontSize: 30 }}>{parseFloat(valorComissao).toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</b>
                                                                    </div>
                                                                    {id ?
                                                                        <div class="mb-3 col-md-3" style={{ marginTop: 20 }}>
                                                                            <label class="form-label" for="basic-icon-default-fullname">Valor com Desconto</label>
                                                                            <div class="input-group input-group-merge">
                                                                                <span id="basic-icon-default-fullname2" class="input-group-text"><i
                                                                                    class="bx bx-money"></i></span>
                                                                                {/* <input onChange={handleValor} value={valor} type="text" class="form-control" /> */}
                                                                                <CurrencyInput className="form-control" id="email2" prefix="R$ " value={valorDesconto} onChange={handleValorDesconto} decimalSeparator="," thousandSeparator="." />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }

                                                                    <div style={{ marginTop: 20 }} className='col-md-12'>
                                                                        <label><b>Observação</b></label>
                                                                        <p>Informamos que o valor indicado nesta cotação é uma estimativa, baseada nas informações preliminares fornecidas, como cubagem, peso e local de entrega. Destacamos que a taxa de coleta, assim como outros detalhes da cotação, deverá ser confirmada diretamente com um de nossos consultores no momento da geração da cotação. Estamos à disposição para esclarecer dúvidas e oferecer suporte em todas as etapas do processo.</p>
                                                                    </div>

                                                                </div>
                                                            </>
                                                            :
                                                            null
                                                        }

                                                        <div className="mt-2">
                                                            {id ?
                                                                <button onClick={() => enviaCotacao()} type="submit" className="btn btn-primary me-2">Atualizar Cotação</button>
                                                                :
                                                                <button onClick={() => enviaCotacao()} type="submit" className="btn btn-primary me-2">Gerar Cotação</button>
                                                            }

                                                            {cotacao.dados ?
                                                                <button onClick={() => enviarCotacao()} type="submit" className="btn btn-success me-2">Enviar Cotação a Entrega Já</button>
                                                                :
                                                                null
                                                            }
                                                            <button onClick={() => window.history.back()} type="reset" className="btn btn-outline-secondary">Voltar</button>
                                                            {link ?
                                                                <p style={{ marginTop: 20, fontSize: 15 }}><b>Envie este lindk ao seu cliente:</b> {link}</p>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* /Account */}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}