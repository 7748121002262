import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import { login, getNota } from '../../services/Brudam'
import firebase from 'firebase';
import moment from 'moment';

export default function Monitoramento() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        cotacao: firebase.firestore().collection('cotacao'),
        monitoramento: firebase.firestore().collection('monitoramento'),
    })
    const [monitoramento,setMonitoramento] = useState({})
    const [mes, setMes] = useState('')
    const [ano, setAno] = useState('')
    const [tipo, setTipo] = useState('')
    const [usuario, setUsuario] = useState({})
    const [cotacoes, setCotacoes] = useState([])
    const [agentes, setAgentes] = useState([])

    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.monitoramento.doc('cotacao').onSnapshot((res) => {
                    setMonitoramento(res.data())
                })
            }
        })

    }, {})

    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleTipo = (event) => {
        event.persist();
        setTipo(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-sm-6 col-lg-12 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="bx bxs-check-circle" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.site}</h4>
                                                </div>
                                                <p className="mb-1">Cliques do Site</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <p className="mb-1"><b>Dados da Página de Solicitante</b></p>
                                                <p style={{marginTop:10}}>Tela que diz respeito aos dados do solicitante com empresa</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="bx bxs-search" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.visualizacaoDados}</h4>
                                                </div>
                                                <p className="mb-1">Visualização </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-success"><i className="bx bxs-check-circle" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.confirmacaoDados}</h4>
                                                </div>
                                                <p className="mb-1">Confirmação </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <p className="mb-1"><b>Dados da página Origem Destino</b></p>
                                                <p style={{marginTop:10}}>Tela que diz respeito aos dados de Origem e Destino</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="bx bxs-search" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.visualizacaoOrigemDestino}</h4>
                                                </div>
                                                <p className="mb-1">Visualização </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-success"><i className="bx bxs-check-circle" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.confirmacaoOrigemDestino}</h4>
                                                </div>
                                                <p className="mb-1">Confirmação </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <p className="mb-1"><b>Dados da página de Volumes</b></p>
                                                <p style={{marginTop:10}}>Tela que diz respeito aos dados da adição dos volumes para cotação</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="bx bxs-search" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.visualizacaoVolumes}</h4>
                                                </div>
                                                <p className="mb-1">Visualização</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-success"><i className="bx bxs-check-circle" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.confirmacaoVolumes}</h4>
                                                </div>
                                                <p className="mb-1">Confirmação</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <p className="mb-1"><b>Dados da página de Serviços</b></p>
                                                <p style={{marginTop:10}}>Tela que diz respeito aos dados de seleção do serviço</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="bx bxs-search" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.visualizacaoServicos}</h4>
                                                </div>
                                                <p className="mb-1">Visualização </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-success"><i className="bx bxs-check-circle" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.confirmacaoServicos}</h4>
                                                </div>
                                                <p className="mb-1">Confirmação </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <p className="mb-1"><b>Dados da página de Resumo / Resultado</b></p>
                                                <p style={{marginTop:10}}>Tela que diz respeito a última tela onde o cliente faz a cotação e confirma os valores</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-info"><i className="bx bxs-search" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.visualizacaoResumo}</h4>
                                                </div>
                                                <p className="mb-1">Visualização</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-lg-4 mb-4">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body">
                                                <div className="d-flex align-items-center mb-2 pb-1">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-success"><i className="bx bxs-check-circle" /></span>
                                                    </div>
                                                    <h4 className="ms-1 mb-0">{monitoramento.confirmacaoResultado}</h4>
                                                </div>
                                                <p className="mb-1">Confirmação</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="container-xxl flex-grow-1 ">
                                <div className="card">
                                    <h5 className="card-header">Agentes para validar</h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Agente</th>
                                                    <th>CNPJ</th>
                                                    <th>Empresa</th>
                                                    <th>Status</th>
                                                    <th>Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {agentes.map((agente) =>
                                                    <tr key={agente.id}>
                                                        <td>{agente.agente}</td>
                                                        <td>{agente.cnpj}</td>
                                                        <td>{agente.nomeEmpresa}</td>
                                                        <td><span class="badge bg-label-warning me-1">Pendente</span></td>
                                                        <td>
                                                            <button onClick={() => window.location = '/detalheAgente?a=' + agente.id} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {agentes.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem agentes para validar</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div style={{ marginTop: 20 }} className="container-xxl flex-grow-1 ">
                                <div className="card">
                                    <h5 className="card-header">Cotações para validar</h5>
                                    <div className="table-responsive text-nowrap">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Cotação</th>
                                                    <th>Origem</th>
                                                    <th>Destino</th>
                                                    <th>Agente</th>
                                                    <th>Valor</th>
                                                    <th>Status</th>
                                                    <th>Ação</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table-border-bottom-0">
                                                {cotacoes.map((cotacao) =>
                                                    <tr key={cotacao.id}>
                                                        <td>{cotacao.cotacao}</td>
                                                        <td>{cotacao.origem.localidade}-{cotacao.origem.uf}</td>
                                                        <td>{cotacao.destino.localidade}-{cotacao.destino.uf}</td>
                                                        <td>{cotacao.tipo}</td>
                                                        <td>{cotacao.valorTotal.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                                                        <td>
                                                            {cotacao.status === 'PENDENTE' ?
                                                                <span class="badge bg-label-warning me-1">Pendente</span>
                                                                : cotacao.status === 'ANDAMENTO' ?
                                                                    <span class="badge bg-label-info me-1">Em Andamento</span>
                                                                    : cotacao.status === 'FINALIZADO' ?
                                                                        <span class="badge bg-label-success me-1">Finalizado</span>
                                                                        :
                                                                        <span class="badge bg-label-danger me-1">Cancelado</span>
                                                            }
                                                        </td>
                                                        <td>
                                                            <button onClick={() => window.location = '/detalheCotacao?c=' + cotacao.id} type="button" className="btn rounded-pill btn-icon btn-primary">
                                                                <span className="tf-icons bx bx-search" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                                {cotacoes.length === 0 ?
                                                    <p style={{ textAlign: 'center', padding: 10 }} >Sem cotações cadastrados</p>
                                                    :
                                                    null
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div> */}

                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}