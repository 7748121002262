import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import { encode, decode } from 'js-base64'

export default function Login() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios')
    })
    const [email, setEmail] = useState('')
    const [senha, setSenha] = useState('')

    useEffect(() => {
        mensagemLoading('Carregando perfil...')
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    if (res.data().usuario === 'AGENTE') {
                        window.location = '/agente'
                    } else if (res.data().usuario === 'COTACAOADMIN') {
                        window.location = '/admin'
                    } else {
                        servicos.auth.signOut();
                    }
                })
            } else {
                Swal.close()
            }
        })
    },[])

    function login() {
        if (!email) {
            mensagemAlerta('Digite seu Email')
            return
        }
        if (!senha) {
            mensagemAlerta('Digite sua senha')
            return
        }
        mensagemLoading('Buscando cadastrado..')
        servicos.usuarios.where('email', '==', email).get().then((res) => {
            if (res.size === 0) {
                mensagemAlerta('Email não cadastrado no sistema')
            } else {
                firebase.auth().signInWithEmailAndPassword(email, senha).then((res) => {
                    servicos.usuarios.doc(res.user.uid).get().then((user) => {
                        if (user.data().status === 'INATIVO') {
                            Swal.close();
                            mensagemAlerta('Usuário não possui permissão para acessar o sistema! Favor verifique o administrador.')
                            firebase.auth().signOut();
                        } else {
                            if (user.data().usuario === 'COTACAOADMIN') {
                                window.location = '/admin';
                                return
                            }
                            if (user.data().usuario === 'AGENTE') {
                                window.location = '/agente';
                                return
                            }
                        }
                    })
                }).catch((error) => {
                    var errorCode = error.code;
                    if (error) {
                        if (errorCode === 'auth/invalid-email') {
                            Swal.close()
                            mensagemAlerta('Email incompleto ou não é válido')
                        } else if (errorCode === 'auth/user-not-found') {
                            Swal.close()
                            mensagemAlerta('Email não cadastrado no Carga Já! Cadastre-se ou verifique novamente')
                        } else if (errorCode === 'auth/wrong-password') {
                            Swal.close()
                            mensagemAlerta('Senha Incorreta')
                        }
                    }
                });
            }
        })
    }

    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    {/* Register */}
                    <div className="card">
                        <div className="card-body">
                            {/* Logo */}
                            <div className="app-brand justify-content-center">
                                <a href="/" className="app-brand-link gap-2">
                                    <img src="https://entregaja.com.br/assets/images/logo/logo.png" />
                                </a>
                            </div>
                            {/* /Logo */}
                            <h4 className="mb-2">Bem vindo</h4>
                            <p className="mb-4">Sistema de cotação da Entrega Já</p>
                            <div className="mb-3">
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" value={email} onChange={handleEmail} className="form-control" id="email" name="email-username" placeholder="Digite seu Email" autofocus />
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <div className="d-flex justify-content-between">
                                        <label className="form-label" htmlFor="password">Senha</label>
                                        {/* <a href="auth-forgot-password-basic.html">
                                            <small>Esqueci a senha</small>
                                        </a> */}
                                    </div>
                                    <div className="input-group input-group-merge">
                                        <input value={senha} onChange={handleSenha} type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password" />
                                        <span className="input-group-text cursor-pointer"><i className="bx bx-hide" /></span>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <button onClick={() => login()} className="btn btn-primary d-grid w-100" type="submit">Acessar</button>
                                </div>
                            </div>
                            <p className="text-center">
                                <span>Novo por aqui? </span>
                                <a href="/acesso">
                                    <span>Crie sua conta</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}