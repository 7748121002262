import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase'
import moment from 'moment';
import { encode, decode } from 'js-base64';


export default function Login() {
    const [usuario, setUsuario] = useState({})
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [contato, setContato] = useState('')
    const [perfil, setPerfil] = useState('')
    const [empresa, setEmpresa] = useState('')
    const [senha, setSenha] = useState('')
    const [confirmaSenha, setConfirmaSenha] = useState('')

    const [minuta, setMinuta] = useState('')
    

    useEffect(() => {
        var usuarioRef = localStorage.getItem("usuario");
        if (usuarioRef) {
            const usuarioArray = JSON.parse(usuarioRef);
            setUsuario(usuarioArray)
            setNome(usuarioArray.nome)
            setEmail(usuarioArray.email)
            setContato(usuarioArray.contato)
        } else {
            window.location = '/'
        }

    }, {})

    function cadastraUsuario(){
        if(!nome){
            mensagemAlerta('Digite seu Nome')
            return;
        }
        if(!email){
            mensagemAlerta('Digite seu Email')
            return;
        }
        if(!contato || contato.length < 11){
            mensagemAlerta('Contato vazio ou incompleto. Não esqueça de colocar DD+9+Celular')
            return;
        }
        if(!perfil){
            mensagemAlerta('Selecione seu Perfil')
            return;
        }
        if(!senha){
            mensagemAlerta('A senha não pode ser vazia')
            return;
        }
        if(!confirmaSenha){
            mensagemAlerta('A senha não pode ser vazia')
            return;
        }
        if(senha !== confirmaSenha){
            mensagemAlerta('As senhas não são iguais!')
            return;
        }
        mensagemLoading('Realizando cadastrado..')
        // firebase.firestore().collection('rastreio').where('email','==',email).get().then((res)=>{
        //     if(res.size > 0){
        //         mensagemAlerta('Este email já está cadastrado em nosso sistema')
        //     }else{
        //         firebase.firestore().collection('rastreio').add({
        //             nome:nome,
        //             email:email,
        //             contato:contato,
        //             perfil:perfil,
        //             empresa:empresa,
        //             cadastro:true,
        //             dataCadastro:moment().format('YYYY-MM-DD HH:mm')
        //         }).then((ref)=>{
        //             var pass = encode(confirmaSenha+ref.id);
        //             firebase.firestore().collection('rastreio').doc(ref.id).update({
        //                 id:ref.id,
        //                 pass: pass
        //             }).then(()=>{
        //                 mensagemSucesso('Cadatro realizado com sucesso! Você fará login com seu email e senha!')
        //             })
        //         })
        //     }
        // })
        firebase.firestore().collection('rastreio').where('email','==',email).get().then((res)=>{
            if(res.size > 0){
                mensagemAlerta('Este email já está cadastrado em nosso sistema')
            }else{
                firebase.firestore().collection('usuarios').add({
                    nome:nome,
                    email:email,
                    contato:contato,
                    perfil:perfil,
                    empresa:empresa,
                    cadastro:true,
                    dataCadastro:moment().format('YYYY-MM-DD HH:mm')
                }).then((ref)=>{
                    var pass = encode(confirmaSenha+ref.id);
                    firebase.firestore().collection('rastreio').doc(ref.id).update({
                        id:ref.id,
                        pass: pass
                    }).then(()=>{
                        mensagemSucesso('Cadatro realizado com sucesso! Você fará login com seu email e senha!')
                    })
                })
            }
        })
        

    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleContato = (event) => {
        event.persist();
        setContato(event.target.value);
    }
    const handlePerfil = (event) => {
        event.persist();
        if(event.target.value){
            setPerfil(event.target.value);
        }
    }
    const handleSenha = (event) => {
        event.persist();
        setSenha(event.target.value);
    }
    const handleConfirmaSenha = (event) => {
        event.persist();
        setConfirmaSenha(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success').then(()=>{
            window.location = '/'
        })
    }

    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            {/* Content */}
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card mb-4">
                                            <h5 className="card-header">Complete seu Cadastro</h5>
                                            <div className="card-body row">
                                                <div className="mb-3 col-md-4   ">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Nome</label>
                                                    <input type="text" value={nome} onChange={handleNome} className="form-control" placeholder="Nome e Sobrenome" />
                                                </div>
                                                <div className="mb-3 col-md-5">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Email</label>
                                                    <input type="text" value={email} onChange={handleEmail} className="form-control" placeholder="name@example.com" />
                                                </div>
                                                <div className="mb-3 col-md-3">
                                                    <label htmlFor="exampleFormControlInput1" className="form-label">Contato</label>
                                                    <input type="text" value={contato} onChange={handleContato} className="form-control" placeholder="DDD + 9 + Celular" />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label  className="form-label">Seu Perfil</label>
                                                    <select className="form-select" aria-label="Default select example" onChange={handlePerfil} >
                                                        <option selected>Selecione</option>
                                                        <option value='representante'>Representante</option>
                                                        <option value='consumidor'>Consumidor</option>
                                                    </select>
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label  className="form-label">Senha</label>
                                                    <input type="password" value={senha} onChange={handleSenha} className="form-control" placeholder="********" />
                                                </div>
                                                <div className="mb-3 col-md-4">
                                                    <label  className="form-label">Confirma Senha</label>
                                                    <input type="password" value={confirmaSenha} onChange={handleConfirmaSenha} className="form-control" placeholder="********" />
                                                </div>
                                                <div className="mb-3 col-md-2">
                                                <button onClick={()=> cadastraUsuario()} className="btn btn-primary d-grid w-100" type="button">Cadastrar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}