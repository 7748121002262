import React, { useEffect, useState } from 'react';
import Menu from '../../../components/Menu';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Swal from 'sweetalert2'
import firebase from 'firebase';
import moment from 'moment';

export default function CotacaoAgente(props) {
    // const [dados] = useState()
    const queryParams = new URLSearchParams(window.location.search);
    const [id] = useState(queryParams.get('c'))

    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection('usuarios'),
        melhorias: firebase.firestore().collection('melhorias'),
    })
    // Cadastro
    const [mes, setMes] = useState(moment().format('MM'))
    const [ano, setAno] = useState(moment().format('YYYY'))
    const [titulo, setTitulo] = useState('')
    const [tipo, setTipo] = useState('')
    const [sugestao, setSugestao] = useState('')
    const [status, setStatus] = useState('')
    const [usuario, setUsuario] = useState({})
    const [usuarioMelhoria, setUsuarioMelhoria] = useState({})


    useEffect(() => {
        servicos.auth.onAuthStateChanged((user) => {
            if (user) {
                servicos.usuarios.doc(user.uid).get().then((res) => {
                    setUsuario(res.data())
                })
                if (id) {
                    servicos.melhorias.doc(id).get().then((res) => {
                        setTitulo(res.data().titulo)
                        setTipo(res.data().tipo)
                        setSugestao(res.data().sugestao)
                        setUsuarioMelhoria(res.data().usuario)
                        setStatus(res.data().status)
                    })
                }

            }
        })

    }, [])


    function cadastraMelhoria() {
        if (!titulo) {
            mensagemAlerta('Por favor digite o título')
            return
        }
        if (!tipo) {
            mensagemAlerta('Por favor selecione Tipo')
            return
        }
        if (!sugestao) {
            mensagemAlerta('Por favor digite a sugestão')
            return
        }
        mensagemLoading('Cadsatrando Sugestão..')
        servicos.melhorias.get().then((res) => {
            servicos.melhorias.add({
                titulo: titulo,
                tipo: tipo,
                sugestao: sugestao,
                dataCadastro: moment().format('YYYY-MM-DD HH:mm'),
                ano: moment().format('YYYY'),
                mes: moment().format('MM'),
                status: 'SOLICITADO',
                codigo: 'ME00' + parseInt(res.size + 1),
                usuario: {
                    id: usuario.id,
                    nome: usuario.nome ? usuario.nome : usuario.agente,
                    email: usuario.email,
                    tipo: usuario.usuario
                }
            }).then((melhoria) => {
                servicos.melhorias.doc(melhoria.id).update({
                    id: melhoria.id
                }).then(() => {
                    mensagemSucesso('Sugestão enviada com sucesso! A equipe irá analisar sua sugestão. Para acompanhar volte a esta tela e filtre pelo seu codigo')
                })
            })
        })
    }

    const handleTitulo = (event) => {
        event.persist();
        setTitulo(event.target.value);
    }
    const handleTipo = (event) => {
        event.persist();
        setTipo(event.target.value);
    }
    const handleSugestao = (event) => {
        event.persist();
        setSugestao(event.target.value);
    }

    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }

    // Mensagens
    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        Swal.fire('Sucesso', msg, 'success')
    }
    function mensagemErro(msg) {
        Swal.fire('Error', msg, 'error')
    }
    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Menu />
                <div class="layout-page">
                    <div>
                        <Header />
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    {usuario.id ?
                                        <div className="col-md-12">
                                            <div className="card mb-4">
                                                <h5 className="card-header">Nova Melhoria</h5>
                                                <hr className="my-0" />
                                                <div className="card-body">
                                                    <div onsubmit="return false">
                                                        <div className="row">
                                                            <div class="mb-3 col-md-6">
                                                                <label class="form-label" for="basic-icon-default-fullname">Titulo</label>
                                                                <div class="input-group input-group-merge">
                                                                    <input onChange={handleTitulo} value={titulo} type="text" class="form-control" />
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Tipo</label>
                                                                <div class="input-group input-group-merge">
                                                                    <select disabled={id ? true : false} id="defaultSelect" className="form-select" onChange={handleTipo}  >
                                                                        <option>Selecione</option>
                                                                        <option value={'MELHORIA'} selected={id && tipo === 'MELHORIA' ? 'selected' : null}>Melhoria</option>
                                                                        <option value={'CORRECAO'} selected={id && tipo === 'CORRECAO' ? 'selected' : null}>Correção</option>
                                                                        <option value={'CRIACAO'} selected={id && tipo === 'CRIACAO' ? 'selected' : null}>Criação</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-3">
                                                                <label class="form-label" for="basic-icon-default-fullname">Status</label>
                                                                <div class="input-group input-group-merge">
                                                                    <select disabled={id ? true : false} id="defaultSelect" className="form-select" >
                                                                        <option>Selecione</option>
                                                                        <option selected={id && status === 'SOLICITADO' ? 'selected' : null}>SOLICITADO</option>
                                                                        <option selected={id && status === 'DESENVOLVIMENTO' ? 'selected' : null}>DESENVOLVIMENTO</option>
                                                                        <option selected={id && status === 'FINALIZADO' ? 'selected' : null}>FINALIZADO</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 col-md-12">
                                                                <label class="form-label" for="basic-icon-default-fullname">Sugestão</label>
                                                                <div class="input-group input-group-merge">
                                                                    <textarea onChange={handleSugestao} value={sugestao} type="text" class="form-control" />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        {usuario.usuario !== 'AGENTE' ?
                                                            <div className="mt-2">
                                                                {id && usuario.usuario !== 'AGENTE' ?
                                                                    <button onClick={() => cadastraMelhoria()} type="submit" className="btn btn-primary me-2">Atualizar Melhoria</button>
                                                                    :
                                                                    <button onClick={() => cadastraMelhoria()} type="submit" className="btn btn-primary me-2">Cadatrar Melhoria</button>
                                                                }
                                                            </div>
                                                            :
                                                            <div className="mt-2">
                                                                {!id ?
                                                                    <button onClick={() => cadastraMelhoria()} type="submit" className="btn btn-primary me-2">Cadatrar Melhoria</button>
                                                                    :
                                                                    null

                                                                }
                                                            </div>
                                                        }

                                                    </div>
                                                </div>
                                                {/* /Account */}
                                            </div>
                                        </div>
                                        :
                                        null
                                    }

                                </div>
                            </div>


                            <Footer />
                            <div className="content-backdrop fade" />
                        </div>
                        {/* Content wrapper */}
                    </div>
                </div>

            </div>
        </div>

    );
}